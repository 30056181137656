import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faToggleOn, faTimesCircle, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';

import { formattedDateSm } from '../../utils/utils';

import { setTitle } from '../../redux/actions/controlsActions';
import { getDocs, updateFile, destroyFile, clearErrors } from '../../redux/actions/fileUploadActions';

import Spinner from "../Spinner/Spinner";

import classes from './Documents.module.css';

import Help from '../Help/Help';

import {
  isMobile
} from 'react-device-detect';

const componentTitle = "Documents";

const formsDocsHelp = [
  {
    "paragraph": `The following forms and files can be saved to your computer or mobile device.  You can
    fill out any forms if they're editable or print them and fill them out by hand.`
  },
  {
    "numbered_list": [
      "Right-click the link",
      "Select \"save as\" or \"Save target as\" (this will vary depending on your browser type)",
      "Save to your document folder.",
      "Open it on your local computer / mobile device."
    ]
  }
];

const SiteDocuments = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const fileUpload = useSelector(state => state.fileUpload);
  const urlTime = new Date().getTime();
  const [sectionCollapsed, setSectionCollapsed] = useState();
  let linkTarget = (isMobile ? "_blank" : "_whdocs");
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Site Documents" });
  }, []);

  useEffect(() => {
    dispatch(setTitle(componentTitle))
    let onlyActive = true;
    if (auth.site_admin && fileUpload.editDocs) {
      onlyActive = false;
    }
    if (fileUpload.working === false && fileUpload.uploading === false) {
      dispatch(getDocs(onlyActive));
    } else {
      console.log("SiteDocuments: can't get docs...  Working...")
    }
  }, [fileUpload.uploading])

  useEffect(() => {
    if (fileUpload.docTypes.length > 0) {
      // Initialize the sectionCollapsed
      let initialSections = {};
      fileUpload.docTypes.forEach((dt) => {
        initialSections[dt.name] = !dt.collapsed;
      })
      setSectionCollapsed(initialSections);
    }
  }, [fileUpload.docTypes])

  const showSection = (docType) => {
    if (!sectionCollapsed) {
      return true;
    }
    return sectionCollapsed[docType.name];
  }

  const setShowSection = (docType, show) => {
    let section = {};
    if (sectionCollapsed) {
      section = {...sectionCollapsed};
    }
    section[docType.name] = show;
    setSectionCollapsed(section);
  }
  const activateDoc = (doc) => {
    let cancelDoc = window.confirm(`Are you sure you want to activate the "${doc.name}" link?`);
    if (cancelDoc) {
      // alert(`Woulda canceled the doc with ID: ${doc.id}`)
      const fileJson = {
        id: doc.id,
        active: true
      };
      dispatch(updateFile(fileJson))
    }
    /* dispatch(update...*/
  }
  const deactivateDoc = (doc) => {
    let cancelDoc = window.confirm(`Are you sure you want to remove the "${doc.name}" link?`);
    if (cancelDoc) {
      // alert(`Woulda canceled the doc with ID: ${doc.id}`)
      const fileJson = {
        id: doc.id,
        active: false
      };
      dispatch(updateFile(fileJson))
    }
  }
  const genActivateOrTrashBtn = (doc) => {
    if (auth.app_admin && fileUpload.editDocs) {
      if (auth.site_admin & doc.active === false) {
        return (
          <React.Fragment>
            <FontAwesomeIcon
              onClick={ (e) => {console.log(`Activating doc ${doc.id}`);activateDoc(doc)} }
              icon={faToggleOn}
              size="1x"
              className={classes.Icon}
            />
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <FontAwesomeIcon
              onClick={ (e) => {console.log(`Deactivating doc ${doc.id}`);deactivateDoc(doc)} }
              icon={faTrash}
              size="1x"
              className={classes.Icon}
            />
          </React.Fragment>
        )
      }
    }
    return null;
  }
  const genEditButton = (docId) => {
    if (auth.app_admin && fileUpload.editDocs) {
      return (
        <React.Fragment>
          <FontAwesomeIcon
            onClick={ (e) => {console.log(`Editing doc ${docId}`);history.push(`/edit_doc/${docId}`)} }
            icon={faEdit}
            size="1x"
            className={classes.Icon}
          />
        </React.Fragment>
      )
    }
    return null;
  }

  const destroyeDoc = (doc) => {
    let destroyDoc = window.confirm(`Are you sure you want to delete the doc at "${doc.name}" ?`);
    if (destroyDoc) {
      dispatch(destroyFile(doc.id))
    }
  }
  const genDestroyButton = (doc) => {
    if (auth.site_admin && fileUpload.editDocs) {
      return (
        <React.Fragment>
          <FontAwesomeIcon
            onClick={ (e) => {console.log(`DELETING doc ${doc.id}`);destroyeDoc(doc)} }
            icon={faTimesCircle}
            size="1x"
            className={classes.Icon}
          />
        </React.Fragment>
      )
    }
    return null;
  }

  const getDescription = (doc) => {
    if (doc.description) {
      return `- ${doc.description}`;
    }
    return null;
  }

  const trackFileDownload = (fileInfo) => {
    // ReactGA.event({
    //   category: fileInfo.name,
    //   action: "download",
    //   label: fileInfo.name, // optional
    //   value: fileInfo.id, // optional, must be a number
    //   nonInteraction: true, // optional, true/false
    //   transport: "xhr ", // optional, beacon/xhr/image
    // });
//     debugger
// alert(fileInfo.name)
    ReactGA.send({ hitType: "pageview", page: fileInfo.url, title: fileInfo.name });
  }
  const getSiteAdminDocs = () => {
    if (!auth.site_admin) {
      return null;
    }
    return fileUpload.siteDocs.map((f) => {
      if (f.doc_type === 'JSON') {
        // alert(`FileName: ${f.doc_file_name}`)
        return (
          <React.Fragment key={`InfoDoc${f.id}`}>
            {genDestroyButton(f)}{genActivateOrTrashBtn(f)}{genEditButton(f.id)}
            <a
              className={classes.DocumentLink}
              href={`${f.url}?${urlTime}`}
              target={linkTarget}>{f.name}
              onClick={() => {trackFileDownload(f)}}
            </a>
            <br />
          </React.Fragment>
        )
      }
    })
  }

  const renderSiteAdminDocs = () => {
    if (auth.site_admin && fileUpload.editDocs) {
      return (
        <div className={classes.DocumentContainer}>
          <h5>Site Admin Docs (ONLY JSON for now)</h5>
          {getSiteAdminDocs()}
        </div>    
      )
    }
    return null;
  }

  const renderEditButton = () => {
    if (fileUpload.editDocs) {
      return (
        <React.Fragment>
          <Button
            style={{display: 'inline-block', float: 'right', marginRight: '10px'}}
            onClick={() => {dispatch(clearErrors());history.replace('/new_doc')}}
          >
            Add Doc
          </Button>
        </React.Fragment>
      )
    }
    return null;
  }

  const lastUpdatedAt = (doc) => {
    if (auth.site_admin) {
      return <span style={{fontSize: '0.7em'}}>(Last Updated - {formattedDateSm(doc.updated_at)})</span>
    } else {
      return null;
    }
  }
  const renderDocsForDocType = (docType) => {
    let docs = [];
    fileUpload.siteDocs.forEach((f) => {
      if (f.doc_type === docType.name /*&& f.active === true*/) {
        docs.push (
          <React.Fragment key={`SiteDoc${f.id}`}>
            <p style={{marginBottom: '2px'}}>
            {genDestroyButton(f)}{genActivateOrTrashBtn(f)}{genEditButton(f.id)}
            <a className={classes.DocumentLink} href={`${f.url}?${urlTime}`} target={linkTarget} onClick={() => {trackFileDownload(f)}}>{f.name}</a>{getDescription(f)} {lastUpdatedAt(f)}
            </p>
          </React.Fragment>
        )
      }
    })
    return docs;
  }

  const renderDocSection = (docType, docsForType) => {
    let docSection = {};
    let colIcon = faPlus;

    if (docType.collapsed) {
      if (showSection(docType)) {
        colIcon = faMinus;
      }
      docSection = (
        <div key={`sdh-${docType.id}`} className={classes.DocumentContainer}>
          <h5>
            <div onClick={() => {setShowSection(docType, !showSection(docType))}} className={classes.Icon} style={{display: 'inline-block'}}>
              <FontAwesomeIcon icon={colIcon} />
            </div>
            &nbsp;{docType.name}
          </h5>
          <Collapse key={`collapse-${docType.id}`} in={showSection(docType)}>
            <div>
            {docsForType}
            </div>
          </Collapse>
        </div>
      )
    } else {
      docSection = (
        <div key={`sdh-${docType.id}`} className={classes.DocumentContainer}>
          <h5>{docType.name}</h5>
          {docsForType}
        </div>
      );
    }
    return docSection;
  }
  const renderAllSiteDocs = () => {
    return fileUpload.docTypes.map((docType) => {
      if (docType.name !== 'JSON') {
        const docsForType = renderDocsForDocType(docType);
        if (docsForType.length > 0) {
          return renderDocSection(docType, docsForType);
        }
      }
    })
  }

  const renderUploadErrors = () => {
    if (fileUpload.error) {
      return (
        <h5 className={classes.PageTitle} style={{color: 'red'}}>{fileUpload.error}</h5>
      )
    }
    return null;
  }
  console.log(`SiteDocuments: ${fileUpload.siteDocs.length}`)
  if (fileUpload.siteDocs.length === 0 || fileUpload.trustees.length === 0 || fileUpload.working) {
    return <Spinner title={(fileUpload.uploading ? "Uploading file.  Please wait...  Do not refresh or reload as this could abort the upload...":"Loading docs...")} />;
  }

  return (
    <div>
      <h4 className={classes.PageTitle}>
        <div style={{display: 'inline-block'}}>Site Documents and Forms</div>
        <Help iconStyle={{float: 'none', display: 'inline-block', marginLeft: '10px', fontSize: '0.3em'}} title="Association Forms and Docs" body={formsDocsHelp} />
        {renderEditButton()}
      </h4>
      {renderUploadErrors()}

      {renderSiteAdminDocs()}

      {renderAllSiteDocs()}

      {/* TODO: Special case for trustees json file.  This could / should be generalized but for now since its just me its fine */}
      <div className={classes.DocumentContainer}>
        <h5>Contact info for Board of Trustees</h5>
        <Row className={classes.DesktopOnly}>
          <Col className={classes.Col} sm={2}><b>Name</b></Col>
          <Col sm={2}><b>Role</b></Col>
          <Col sm={2}><b>Term Ending</b></Col>
          <Col sm={2}><b>Phone #</b></Col>
          <Col sm={4}><b>Assignments</b></Col>
        </Row>

        {fileUpload.trustees.map( (trustee) => {
          return (
            <Row className={classes.TrusteeContainer} key={trustee.phone}>
              <Col xs={4} className={classes.MobileOnly}><b>Name</b></Col><Col xs={8} sm={2}>{trustee.name}</Col>
              <Col xs={4} className={classes.MobileOnly}><b>Role</b></Col><Col xs={8} sm={2}>{trustee.role}</Col>
              <Col xs={4} className={classes.MobileOnly}><b>Term Ending</b></Col><Col xs={8} sm={2}>{trustee.term_end_date}</Col>
              <Col xs={4} className={classes.MobileOnly}><b>Phone</b></Col><Col xs={8} sm={2}><a href={`tel:${trustee.phone}`}>{trustee.phone}</a></Col>
              <Col xs={4} className={classes.MobileOnly}><b>Assignment</b></Col><Col xs={8} sm={4} 
                   dangerouslySetInnerHTML={{__html:  trustee.assignment}}></Col>
            </Row>
          );
        })}
        <Row>
          <Col>
            <div style={{marginTop: '10px', fontSize: '0.9em'}}>
              <i>(Bold text in committee assignments represents Trustee with the lead role for this area of responsibility)</i>
            </div>
            <div style={{marginTop: '10px', fontSize: '1.1em'}}>
            General email address for the Board of Trustees: <a href="mailto:wheboardoftrustees@gmail.com">wheboardoftrustees@gmail.com</a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SiteDocuments;